import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";
import { useAlternateLangs } from "../../components/Hreflangs";

// breadcrumbs
const breadCrumbLevels = {
  Hjem: "/",
  "Analytics Consulting": "/no/analyse-rådgivning",
  "Google Analytics Oppsett": "/no/google-analytics-oppsett"
};

// hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/google-analytics-setup"
);

const AnalyticsSetup = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title={`Sett opp Google Analytics fra bunnen av! [${currentYear} Guide]`}
        description="Guide om hvordan du konfigurerer en moderne Google Analytics-oppsett. Måloppsett, hendelsessporing og beste praksis. Veiledning fra sertifisert Google Analytics-konsulent."
        lang="no"
        image="google-analytics-setup-realtime-report-hero"
        alternateLangs={alternateLangs}
        canonical="/no/google-analytics-oppsett"
      />
      <MainContent article>
        <ImgScreenshot
          src="google-analytics-einrichten/google-analytics-setup-realtime-report-hero.png"
          alt="mann som jobber med Google Analytics-oppsett på laptop"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Google Analytics Oppsett Guide</H>
        <p>
          En av de første oppgavene for en ny nettside er å sette opp Google Analytics. Etter all tid og innsats du har investert i å bygge en nettside, vil du selvfølgelig måle og analysere brukeradferden. Du
          kan ha spørsmål som:
        </p>

        <ul>
          <li>Hvordan presterer innholdet mitt?</li>
          <li>Hvilken produktkategori er mest populær?</li>
          <li>Hvor mange konverteringer utløste Facebook-kampanjen min?</li>
          <li>Ser besøkende faktisk på videoen jeg produserte?</li>
          <li>Er det noen sider som presterer spesielt dårlig?</li>
          <li>Deler besøkende noe på sosiale medier?</li>
          <li>Går folk ut av kjøpsprosessen underveis?</li>
        </ul>

        <p>
          Alle antagelser om brukeradferd og kampanjeytelse må valideres. <a href="https://w3techs.com/technologies/details/ta-googleanalytics" target="_blank">55% av alle nettsteder på internett bruker Google Analytics</a> til dette. Faktisk har det en <a href="https://w3techs.com/technologies/overview/traffic_analysis" target="_blank">markedsandel på 84% i markedet for trafikkanalyseverktøy</a>, så du trenger ikke bekymre deg for at du velger feil analyseverktøy.
        </p>
        <p>
          Et webanalyseverktøy lar deg ta beslutninger basert på data i stedet for gjetninger. Slike beslutninger kan for eksempel handle om hvilken type innhold du skal investere i eller hvor mye budsjett du bør bruke på annonsekampanjer.</p>
        <p>Derfor er det av avgjørende betydning å sette opp Google Analytics profesjonelt, slik at du sporer de riktige KPIene for analysen din. Med et slikt oppsett på plass vil du ha mange tilgjengelige måledata å dykke ned i.
        </p>
        <H as="h2">Typer av Google Analytics-oppsett</H>
        <p>
          Typen nettside er en nøkkelfaktor for et Google Analytics-oppsett:
          <br />
          Driver du en nettbutikk eller selger produkter? <br />
          Da er {/* <Link to="/en/ecommerce-analytics"> */}
          e-commerce analytics{/* </Link> */} veien å gå. Det er mer produkt- og transaksjonsorientert og vil
          gi deg nyttige innsikter du ikke vil gå glipp av.
        </p>
        <p>
          Hvis du driver en innholdsorientert side om merkevaren din, er et typisk Google Analytics sporingsoppsett det
          rette valget. <br />
          Du kan spore brukerinteraksjoner med <Link to="/no/hendelsessporing">hendelsessporing</Link> og overvåke
          viktige nettside-KPIer med{" "}
          <Link to="/no/google-analytics-oppsett">Google Analytics mål</Link>.
        </p>

        <p>
          E-commerce analytics oppsett krever mer data om produkter og transaksjoner. Derfor er konfigurasjonen
          litt mer kompleks, involverende tilpasset JavaScript for å bygge et såkalt{" "}
          <Link to="/no/data-lag">data layer</Link>
          . <br />Et data layer er i hovedsak en lagring av data som holder informasjon om hver side. Google
          Analytics vil deretter gjenkjenne og bruke den når den er tilgjengelig.
        </p>
        <p>
          Ikke bekymre deg hvis du innser at du trenger å sette opp Google Analytics for en e-commerce side. Du kan
          aktivere e-commerce funksjonalitet senere og også forbedre data layer når du er klar for det.{" "}
          <br />
          Inntil da vil e-commerce rapporter være tomme, men andre rapporter vil fungere helt fint.{" "}
        </p>

        <H as="h2">Hvor får du Google Analytics sporingskode?</H>
        <p>
          Besøk{" "}
          <a href="https://analytics.google.com" target="_blank" rel="noopener">
            analytics.google.com
          </a>{" "}
          og klikk på <strong>Registrer deg</strong> knappen for å opprette en Google Analytics-konto. Du vil få Google
          Analytics sporingskoden når du følger trinnene nedenfor.
        </p>
        <ImgScreenshot
          src="google-analytics-setup/singup-for-google-analytics-tracking-code.png"
          alt="Google Analytics registreringsskjermbilde"
          className="article-img"
        />
        <ul>
          <li>
            Etter at du klikker på <strong>registrer deg</strong> knappen, fortsett og velg et eiendomsnavn og URL
            etterfulgt av din bransje og tidssone.{" "}
          </li>
          <li>
            Jeg anbefaler å <strong>deselektere alle datadeling-alternativer</strong> hvis du ikke er helt på toppen av din
            personvernpolicy. Men det betyr at Google <strong>ikke</strong> vil dele demografi- og interesserapporter
            med deg. <br />
            Hvis du vil bruke disse rapportene, må du kanskje oppdatere personvernpolicyen din. Dette er for å informere brukerne om at du deler data om deres surfeatferd med Google.
          </li>
          <li>
            Etter at du er ferdig, mottar du din <strong>Google Analytics sporings-ID</strong> (f.eks.{" "}
            <em>UA-141112345-1</em>). Sammen med den vil du motta kodefragmentet som skal settes inn i kildekoden på
            alle sidene dine.
          </li>
        </ul>
        <H as="h2">Hvordan implementere Google Analytics?</H>
        <p>Det er generelt to måter å implementere Google Analytics:</p>

        <ol>
          <li>
            Du limer Google Analytics sporingskoden inn i kildekoden på alle sider. Du kan gjøre dette side for
            side, eller du limer den inn i en felles malfil. Dette kan være header, navigasjon eller footer som alle
            sidene har til felles.
            <br />
            <strong>Jeg anbefaler ikke</strong> denne måten å implementere Google Analytics, med mindre du vil gjøre det
            raskt og enkelt og aldri røre analyseoppsettet igjen. <br />
            De fleste sporingsoppsett gjøres raskere og enklere med et <Link to="/no/tagg-administrasjon">tag management system</Link>. Det er en enklere og mer
            fleksibel prosess, så det er verdt å introdusere enda et <em>verktøy</em> i stabelen.
          </li>

          <li>
            Du bruker et tag management system, som Google Tag Manager, som er ganske populært.
            <br />
            Det finnes andre alternativer som <em>Adobe Launch</em>, <em>Tealium</em>, <em>Ensighten</em> eller{" "}
            <em>Matomo</em> (åpen kildekode), også. <br />
            Generelt tjener de alle samme formål og er svært like. Du kan implementere Google Analytics med et
            ikke-Google tag management system uten bekymring. Google Tag Manager er bare den som alle kan få
            tilgang til enkelt og har flest ressurser tilgjengelig på nettet.
            <br />
            Hvis du vil lære mer om det, sjekk ut min <Link to="/no/google-tag-manager-oppsett">Google Tag Manager veiledning</Link>.
          </li>
        </ol>

        <H as="h2">Hvordan implementere Google Analytics med Google Tag Manager?</H>
        <p>
          Når det gjelder <strong>implementering av Google Analytics via Google Tag Manager,</strong> følg disse trinnene:
        </p>

        <ul>
          <li>
            Besøk{" "}
            <a href="https://tagmanager.google.com" target="_blank" rel="noopener">
              tagmanager.google.com
            </a>{" "}
            og opprett en konto.{" "}
          </li>{" "}
        </ul>
        <ImgScreenshot
          src="google-analytics-setup/google-tag-manager-create-account.png"
          alt="Google Tag Manager opprett konto"
          className="article-img"
        />

        <ul>
          <li>
            Med kontoen oppretter du en container for nettsiden din, så sørg for å velge <strong>Web</strong>{" "}
            for bruken:
          </li>
        </ul>

        <ImgScreenshot
          src="google-analytics-setup/google-analytics-setup-via-google-tag-manager-container-creation.png"
          alt="Google Tag Manager web container opprettelsesskjermbilde"
          className="article-img"
        />
        <p>
          deretter vil du motta <strong>Google Tag Manager kodefragmentet</strong> for implementering:
        </p>

        <ImgScreenshot
          src="google-analytics-setup/google-tag-manager-code-snippet.png"
          alt="Google Tag Manager kodefragment"
          className="article-img"
        />

        <p>
          Legg til den første delen i <code>&lt;head&gt; </code>
          taggen på alle sidene på nettsiden din. Den andre delen legges til inne i <code>&lt;body&gt;</code> taggen.
        </p>
        <p>Etter at Google Tag Manager (GTM) kodefragmentet er lagt til, kan analytics-oppsettet konfigureres på et blunk!</p>

        <ul>
          <li>
            I GTM, klikk på <strong>tags</strong> i venstre navigasjon og <strong>legg til en ny tag</strong>. En
            overlay vil skyve inn fra siden og du kan gi taggen et <strong>navn</strong>, f.eks.{" "}
            <em>Google Analytics Tracking</em>.
          </li>
          <li>Deretter klikker du på taggkonfigurasjonsboksen:</li>

          <ImgScreenshot
            src="google-analytics-setup/google-tag-manager-create-new-tag.png"
            alt="google tag manager opprett ny tag"
            className="article-img"
          />

          <li>
            En liste med tagtyper vil skyve inn på skjermen. <br />
            Velg <strong>Google Analytics - Universal Analytics</strong> taggen og start konfigurasjonen.
          </li>

          <ImgContainerFixed width="462px">
            <ImgScreenshot
              src="google-analytics-setup/google-tag-manager-google-analytics-setup-tag-selection.png"
              alt="google tag manager opprett Google Analytics tag"
              className="article-img"
            />
          </ImgContainerFixed>

          <li>
            Hold <strong>Sporingstype</strong> som <strong>Sidevisning</strong>. <br />
          </li>
          <li>
            Deretter <strong>oppretter du en ny Google Analytics innstillingsvariabel.</strong>
          </li>

          <ImgScreenshot
            src="google-analytics-setup/google-tag-manager-google-analytics-setup-settings.png"
            alt="Google Tag Manager innstillingsvariabel for Google Analytics skjermbilde"
            className="article-img"
          />

          <li>
            For å konfigurere innstillingsvariabelen gir du den først et <strong>navn</strong>. Rett etter, lim inn{" "}
            <strong>Google Analytics sporings-ID</strong> (<em>UA-141112345-1</em>) som du{" "}
            <Link to="#hvor-få-google-analytics-sporingskoden">mottok tidligere</Link>.
          </li>

          <ImgScreenshot
            src="google-analytics-setup/google-tag-manager-google-analytics-setup-tracking-id.png"
            alt="Google Tag Manager Google Analytics oppsett sporings-ID skjermbilde"
            className="article-img"
          />

          <li>
            Klikk <strong>lagre</strong>.
          </li>
          <li>
            Tilbake i tagkonfigurasjonen, klikk på <strong>utløsning</strong> seksjonen for å velge en utløser.
          </li>

          <li>
            Velg <strong>alle sider</strong>, fordi vi vil at Google Analytics kodefragmentet skal kjøre på alle sidene.
          </li>

          <li>
            <strong>Lagre</strong> utløseren så vel som tagkonfigurasjonen og gå tilbake til Google Tag Manager
            oversikten.
          </li>

          <li>
            Trykk på den blå <strong>send</strong> knappen i øverste høyre hjørne. Gi din første containerversjon et{" "}
            <strong>navn</strong> og klikk på <strong>publiser</strong>. <br />
            Nå har du lykkes med å implementere Google Analytics med Google Tag Manager!
          </li>
        </ul>

        <p>
          Du bør se data komme inn i din Google Analytics eiendom etter omtrent en time eller så.
          <br />
          Du kan også ta en titt på sanntidsrapporten for å se om det allerede kommer inn noen data fra ditt
          nåværende besøk.
        </p>
        <ImgScreenshot
          src="google-analytics-setup/google-analytics-realtime-tracking-overview.png"
          alt="Google Analytics sanntidsrapport oversikt"
        />

        <p>
          Legg merke til at du kunne implementere andre verktøy eller skript og utløse dem på <strong>alle sider</strong> på bare noen minutter? <br /> Jeg håper dette får deg til å innse hvor enkelt og kraftig et tag management system er.
        </p>

        <H as="h2">Hvordan sette opp mål i Google Analytics?</H>
        <p>
          Nå som du mottar besøksdata, er det på tide å sette opp <strong>Google Analytics mål</strong>.
        </p>
        <p>Det er stor sannsynlighet for at du selger noe på nettsiden din eller at det finnes et skjema som folk kan fylle ut. </p>
        <p>
          Normalt, etter en bestilling, lander brukere på en <strong>takkeside</strong>. Den sier vanligvis takk for bestillingen og lister opp noen bestillingsdetaljer. <br /> Se i adressefeltet og se hvilken URL den siden har.{" "}
        </p>
        <p>For eksempel kan det se slik ut:</p>
        <p>
          <code>https://www.dinsite.com/takk.php</code>{" "}
        </p>
        <p>
          La oss sette opp et mål for denne adressen og telle hvor ofte denne siden blir besøkt for å indikere hvor mange bestillinger som har funnet sted.
        </p>

        <ul>
          <li>
            Gå til Google Analytics <strong>admin-område</strong> med en oversikt over din konto, eiendom og
            visning. Klikk <strong>mål</strong> innenfor <strong>visning</strong> seksjonen.
          </li>
        </ul>

        <ImgScreenshot
          src="google-analytics-setup/google-analytics-admin-area-goals.png"
          alt="Google Analytics admin-område mål"
          className="article-img"
        />

        <ul>
          <li>
            <strong>Legg til</strong> et nytt mål og velg <strong>tilpasset</strong> i måloppsettet. Deretter{" "}
            <strong>fortsett</strong>.
          </li>
          <li>
            Gi målet ditt et <strong>navn</strong>, for eksempel <em>Nyhetsbrev abonnement</em> eller{" "}
            <em>Bestillingsskjema mottatt</em> og velg type som <strong>destinasjon</strong>. Deretter{" "}
            <strong>fortsett</strong>.
          </li>
          <li>
            Under <strong>destinasjon</strong> er <strong>lik</strong>, legg til URL <strong>stien</strong>, så i
            vårt eksempel:
            <p>
              <code>/thank-you.php</code>
            </p>
            Du kan også være lur og velge <strong>Regulært uttrykk</strong> som matchtype i rullegardinmenyen.
            <br />
            Hvis du ikke vet hva <strong>regulære uttrykk</strong> er: De er en kraftig og praktisk måte å
            matche strenger på. Her kan du lære hvordan du bruker dem på en time eller to:{" "}
            <a href="https://regexone.com/" target="_blank" rel="noopener">
              RegexOne
            </a>
          </li>
          <li>
            Du kan valgfritt tildele en <strong>verdi</strong> til målet ditt. Jeg vil anbefale det, hvis du kan tildele en
            numerisk verdi til hver av disse takk-siden visningene.
            <br /> Det er flere måter å gjøre dette på. Den enkleste er å se på månedlig inntekt fra disse
            takk-sidene og dele den på totalt antall sidevisninger.
            <br />
            Det vil gi deg gjennomsnittet for hver gang målet treffes.
          </li>
          <li>
            <strong>Sett opp en trakt</strong> hvis brukerreisen skal treffe noen spesifikke sider før
            konverteringen. Dette gir mye mening for skjemaer som er delt opp i flere trinn. Du kan sjekke
            senere om en andel av besøkende faller ut av trakten et sted under prosessen. <br />
            Du kan legge til trinn i trakten ved å gi hvert trinn et <strong>navn</strong> og en{" "}
            <strong>sidebane</strong> (<em>se nedenfor</em>). <br />
            Merk at hvis du valgte <strong>lik</strong> i den øvre destinasjonsrullegardinmenyen, må du bruke samme matchtype for traktreglene dine.
            <br />
            Eller hvis du valgte <strong>regulære uttrykk</strong>, må du følge regex-syntaksen i reglene for side-matching.
          </li>
          <li>
            Før du lagrer, klikk på <strong>verifiser mål</strong>. <br />
            Hvis du allerede har noen data i din GA-eiendom, vil den gå gjennom dem og sjekke etter treff i de
            historiske dataene. <br />
            Hvis du har null treff, men vet at det må være noen, har du sannsynligvis gjort en feil ved å spesifisere sidebanene.
            <br /> Så dette er et bra sted å dobbeltsjekke at reglene fungerer, før du fullfører mål-oppsettet.
          </li>
        </ul>

        <ImgScreenshot
          src="google-analytics-setup/set-up-google-analytics-goals.png"
          alt="Google Analytics oppsett for mål skjermbilde"
          className="article-img"
        />

        <p>
          Gratulerer! Du har nettopp satt opp målet ditt og kan spore målkonverteringer fremover! <br />
          Mål bør settes opp for de viktigste konverteringene på nettsiden din. Hvis andre konverteringer er mindre
          kritiske eller kanskje de ikke er representert med sin egen sidebane, kan du sette opp en Google Analytics
          hendelse.
          <br />
          Etter å ha satt opp en hendelse kan du bruke den som grunnlag for målkonverteringene dine.
        </p>

        <H as="h2">Sett opp hendelser i Google Analytics</H>
        <p>
          Prosessen med <Link to="/no/hendelsessporing">å sette opp hendelser i Google Analytics</Link> kan se veldig
          forskjellig ut for hver hendelse. Avhengig av interaksjonen og nettsidens teknologi, er det forskjellige trinn
          å ta. Nedenfor finner du en liste over forskjellige eksempler på hendelsessporing.
        </p>
        <H as="h3">Eksempler på hendelsessporing</H>

        <ul>
          <li>dele innholdet ditt på sosiale medier</li>
          <li>spille av videoen på forsiden din</li>
          <li>trykke på tilbake-knappen til Google søkeresultater</li>
          <li>scrolle ned til bunnen av artikkelen din</li>
          <li>klikke på kontakt-e-posten din</li>
          <li>legge et produkt i handlekurven</li>
          <li>velge en fargevariant av et produkt</li>
        </ul>

        <p>
          Fremgangsmåten for å sette opp scroll-sporing er annerledes enn for å spore museklikk. På dynamiske
          nettsider er HTML-elementer ikke alltid synlige. Derfor vil klikksporing kreve en annen tilnærming,
          enn på statiske nettsider. <br />
          Poenget er, det er mange påvirkende faktorer, så jeg skrev en egen artikkel om hendelsessporing. Der går jeg
          i detalj, steg for steg, og forklarer hvordan du sporer knappetrykk eller andre elementer. Ta en titt på den her:
        </p>
        <p>
          <Link to="/no/hendelsessporing">Hendelsessporing i Google Analytics</Link>
        </p>
        <H as="h2">Sett opp kampanjesporing i Google Analytics</H>
        <p>
          Kampanjesporing er allerede innebygd i hvert Google Analytics-oppsett. Du trenger bare å legge til <Link to="/no/wiki-analytics/utm_parameter">UTM-parametere</Link> til
          lenkene i e-posten din eller sosiale medier-kampanjer.
        </p>
        <p>
          Det er fem parametere du kan legge til enhver lenke som peker til nettsiden din. Den innebygde Google
          Analytics kampanjesporingen gjenkjenner en bruker fra en slik lenke. Den tilskriver deretter besøket til den
          spesifiserte kampanjen, og du kan finne det i kampanjerapporten.
        </p>
        <p>
          La oss ta URLen <code>https://www.dinsite.com/kampanje</code>
          <br />
          og de følgende kampanjesporingsparameterne som et eksempel:
        </p>

        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th>UTM Parameter</th>
              <th>Parameterverdi</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>UTM Source</td>
              <td>Medium</td>
            </tr>
            <tr>
              <td>UTM Medium</td>
              <td>Gjestepost</td>
            </tr>
            <tr>
              <td>UTM Kampanje</td>
              <td>Vårsalget</td>
            </tr>
            <tr>
              <td>UTM Term</td>
              <td>Støvler</td>
            </tr>
            <tr>
              <td>UTM Innhold</td>
              <td>Støvler når det er varmt ute</td>
            </tr>
          </tbody>
        </table>

        <p>
          Dette er den <strong>samme URLen inkludert UTM-parametere</strong>:
        </p>
        <p>
          <code>
            https://www.dinsite.com/kampanje?<strong>UTM_source</strong>
            =medium&amp;<strong>UTM_medium</strong>
            =gjestepost&amp;<strong>UTM_kampanje</strong>=vårsalget&amp;
            <strong>UTM_term</strong>=støvler&amp;<strong>UTM_innhold</strong>
            =støvler-når-det-er-varmt-ute
          </code>
        </p>
        <p>
          Du kan bruke den på sosiale medier eller på andre kilder som lenker til siden din. Google Analytics sporer
          automatisk hvor mange klikk som når deg fra en slik lenke.
        </p>
        <p>
          Legg merke til de <strong>fem UTM-parametrene</strong> i URLen? Du trenger ikke legge til disse manuelt. Det finnes{" "}
          <a
            href="https://ga-dev-tools.appspot.com/campaign-URL-builder/"
            target="_blank"
            title="Verktøy for å bygge lenker med UTM-parametre"
          >
            UTM Builder-verktøy
          </a>{" "}
          som gjør det for deg. Du gir dem bare <strong>lenken til nettsiden din</strong> og verdiene for{" "}
          <strong>UTM-parametrene</strong>. Etterpå vil de gi deg kampanjesporingslenken, inkludert alle UTM-parametre, tilbake.
        </p>

        <H as="h2">Google Analytics Beste Praksiser</H>
        <p>
          Nedenfor er en liste over tilleggs <strong>beste praksiser</strong> for å sette opp Google Analytics. Jeg anvender hver av dem nøye
          for hver GA-oppsett jeg konfigurerer. De er ikke bare <em>gode å ha</em>, men heller nødvendige for å maksimere
          datakvaliteten. <br />
          Filtre kan settes opp i tag management systemet også. Faktisk foretrekker jeg det, fordi JavaScript tillater mer
          avanserte regler når man filtrerer. Likevel er filtre i Google Analytics enklere og gjør jobben nesten like bra.
        </p>
        <ol start="1">
          <li>
            <H as="h3">Ekskluder egen trafikk fra Google Analytics</H>
            Nå som du sporer besøk, ekskluder dine egne sesjoner fra nettsidetrafikken din. Din surfeatferd vil avvike fra vanlige brukere og ellers forvrenge dataene dine. Dette teller spesielt for sider med lav trafikk, da egne sidevisninger veier tyngre totalt sett.
            <br />
            For å <strong>ekskludere din egen trafikk</strong> kan du filtrere deg selv ut etter <strong>IP-adresse</strong>,
            hvis du har en statisk IP.
            <br />
            Hvis ikke, kan du vurdere å få en fra internettleverandøren din, siden de vanligvis ikke koster mye.
          </li>
        </ol>
        <ul>
          <li>
            For å <strong>sette opp IP-ekskludering i Google Analytics</strong>, gå til <strong>Admin-panelet</strong> og
            klikk på <strong>Filtre</strong> i <strong>visning</strong> kolonnen til høyre.
          </li>
          <li>
            Legg til et <strong>navn</strong> for filteret ditt og velg <strong>filtertype</strong> til å være{" "}
            <strong>tilpasset</strong>.
          </li>
          <li>
            Velg <strong>ekskluder</strong> filteralternativet og velg <strong>IP-adresse</strong> i rullegardinmenyen.
          </li>
          <li>
            Skriv deretter inn din statiske IP i <strong>filtermønster</strong> feltet og klikk <strong>lagre</strong>.
          </li>
        </ul>

        <ImgContainerFixed width="415px">
          <ImgScreenshot
            src="google-analytics-setup/exclude-ip-from-google-analytics-setup.png"
            alt="Ekskluder IP fra Google Analytics oppsett skjermbilde"
            className="article-img"
          />
        </ImgContainerFixed>

        <ol start="2">
          <li>
            <H as="h3">Transformer sidebaner til små bokstaver</H>
            En annen justering er å automatisk <strong>transformere sidebanen til små bokstaver</strong>.
            <br />
            Mennesker gjør feil og på et tidspunkt vil noen opprette en URL med store bokstaver i navnet. Hvis
            nettsiden da returnerer samme innhold, som for samme URL med små bokstaver, blir ting rotete. <br /> La meg forklare. <br />
            Vi sender i hovedsak data under to forskjellige sidebaner, selv om dataene er for samme side.
            <br />
            Sidebaner skrevet med varierende store og små bokstaver fører til rotete analyse i Google Analytics. Enhver unik skriving av samme bane vil vises på sin egen rad i rapportene. Etter en stund ender du opp med å se på flere rader og deres metrikker, selv om de burde vært kombinert. <br />
            Hvis du da også får backlinks til samme side, men adressen i lenken var skrevet på en morsom måte,
            er rotet perfekt. Så la oss prøve å holde livet vårt drama-fritt og fikse dette på forhånd.
          </li>
        </ol>
        <ul>
          <li>
            For å <strong>transformere URL-baner til små bokstaver</strong> besøk <strong>Filtre</strong> for visningen din
            igjen og legg til et nytt filter. Gi det et navn og velg <strong>tilpasset</strong> filtertype.
          </li>
          <li>
            Nå velger du <strong>Små bokstaver</strong> og velger <strong>Forespørsels-URI</strong> i feltrullegardinmenyen.
          </li>
        </ul>

        <ImgContainerFixed width="369px">
          <ImgScreenshot
            src="google-analytics-setup/set-up-lowercase-urls-in-google-analytics.png"
            alt="Sett opp små bokstaver i URLer i Google Analytics"
            className="article-img"
          />
        </ImgContainerFixed><br />

        <ul>
          <li>
            Klikk <strong>lagre</strong> for å fullføre. Enkel løsning som sparer mange hodepiner.
          </li>
        </ul>
        <ol start="3">
          <li>
            <H as="h3">Google Analytics Visningsstruktur</H>
            <p>
              En <strong>visning</strong> er der du får tilgang til alle dine Google Analytics rapporter. Hvis det er satt opp filtre for visningen din, vil alle rapporter bli filtrert deretter.
              <br />
              Din <strong>visningsstruktur</strong> bør vanligvis etterligne organisasjonens struktur. Hvis målmarkedene dine f.eks. er mer regionale enn landsbaserte, sett opp visningene dine på samme måte. Det vil komme til nytte når KPIer for regioner er direkte tilgjengelige.
              <br />
              En annen orientering er organisasjonens struktur for budsjettering og markedsføringstiltak. Hvis det gjøres på landsnivå, er det sannsynligvis en god idé å følge den strukturen for visningene også.
              <br />
              Du kan opprette opptil 25 visninger per eiendom. Så, avhengig av antall markeder og markedsføringskanaler, er flere visningsstrukturer mulig.
            </p>
            <H as="h3">Eksempel: Google Analytics visningsstruktur for lokale nettsider</H>
            <p>
              1.0 Master
              <br />
              2.0 Intern trafikk
              <br />
              3.0 Rådata
            </p>
            <p>
              På en eller annen måte er det vanligvis 3 typer visninger. De kan filtreres videre ned etter land
              eller enhet, hvis det gir mening for organisasjonen din.
            </p>
            <H as="h3">Eksempel: Google Analytics visningsstruktur for multinasjonale nettsider</H>
            <p>
              1.1 Master (US)
              <br />
              1.2 Master (UK)
              <br />
              1.3 Master (DE)
              <br />
              1.4 Master (FR)
              <br />
              1.5 Master (ES)
              <br />
              1.6 Master (MX)
              <br />
              1.7 Master (RUS)
              <br />
              2.0 Intern Trafikk
              <br />
              3.0 Rådata
            </p>

            <H as="h3">Eksempel: Google Analytics regional visningsstruktur</H>
            <p>
              1.0 Master (NA)
              <br />
              1.1 Master (EMEA)
              <br />
              1.2 Master (LATAM)
              <br />
              1.3 Master (APEC)
              <br />
              2.0 Intern Trafikk
              <br />
              3.0 Rådata
            </p>

            <p>
              <strong>Master-visningen</strong> er den primære visningen for analyse og ekskluderer all intern trafikk.
            </p>
            <p>
              <strong>Intern trafikk-visningen</strong> inkluderer all intern trafikk. Denne kan også brukes til
              feilsøking og testing. Hvis du setter opp et inkluderingsfilter, vil alt annet ekskluderes
              automatisk.
            </p>
            <p>
              I begynnelsen oppretter Google Analytics en første <strong>All Web Site Data-visning</strong> sammen
              med GA-eiendommen.
            </p>
            <p>
              Behold den, men endre navnet til <strong>Rådata</strong>. Du vil ha en rådatavisning uten noen
              filtre. Du kan ikke angre et filter retroaktivt og se på de ufiltrerte dataene. Derfor er det en beste
              praksis å beholde en visning urørt. For å endre navnet, gå til{" "}
              <strong>Admin &gt; Visning &gt; Visningsinnstillinger</strong>.
            </p>
            <p>
              I de fleste tilfeller fokuserer selskaper innsatsen sin på ett eller noen få land. I så fall opprett
              visninger på landsbasis og sett filtre deretter.
              <br />
              Du kan også opprette visninger per trafikkilde (organisk, betalt, direkte, sosial, etc.) eller enhet (desktop,
              nettbrett, mobil). Jeg bruker Google Analytics-segmenter for det, men det er ingen grunn til å ikke filtrere
              spesifikasjonene allerede i visningen. Jeg liker å holde antallet visninger lavt fordi det holder GA
              brukergrensesnittet rent.
              <br />Jeg kan bli litt rotete med segmenter i visningen og anvende gjenværende spesifikasjoner der.
            </p>
            <p>
              Opprett alle visninger så tidlig som mulig, fordi de bare viser data fra det øyeblikket de ble opprettet. En
              ny visning vil ikke vise noen historiske data fra før opprettelsesdatoen. Hvis du trenger å se tilbake i
              historikken med spesifikke filtre, faller du tilbake på rådatavisningen. Du anvender bare de tiltenkte filtrene
              med segmenter i stedet. På denne måten får du innsikten din, men endrer ikke dataene.
            </p>
            <p>
              <strong>TIPS</strong>: Hvis noen visninger deler de samme filtrene, kan du opprette én og deretter duplisere den
              sammen med alle filtrene. Deretter legger du bare til eventuelle visningsspesifikke filtre til duplikatene.
              <br />
              Sørg for å holde originalvisningen uendret, så du bare legger til filtre på duplikatene. Å tukle med originalvisningen senere vil påvirke de dupliserte visningene også.
            </p>
          </li>
          <li>
            <H as="h3">Google Analytics Varsler</H>
            En annen beste praksis er å <strong>sette opp Google Analytics varsler</strong>. Jeg setter det opp for å få varsler
            for reduksjoner i økter eller når serverens responstid går opp. På denne måten kan jeg se på årsakene
            og potensielt gjøre noe.
          </li>
        </ol>
        <ul>
          <li>
            For å sette opp et varsel i Google Analytics, besøk <strong>Tilpasning</strong> i sidemenyen.
          </li>
          <li>
            Alternativt kan du også besøke <strong>Admin</strong>-området igjen og klikke på{" "}
            <strong>Tilpassede varsler</strong> i visningspanelet.
          </li>
          <li>
            klikk <strong>Tilpassede varsler</strong> og klikk <strong>administrer tilpassede varsler</strong>
          </li>
          <li>
            Å konfigurere et Google Analytics-varsel for reduserende økter er ganske enkelt. Klikk{" "}
            <strong>Nytt varsel</strong> og navngi varslet for eksempel <strong>Øktereduksjon</strong> med{" "}
            <strong>Periode</strong> satt til <strong>Uke</strong>.
          </li>
          <li>Merk også av i boksen for å motta en e-post når varslet utløses.</li>
          <li>
            Det er vanligvis trafikkfluktuasjoner mellom hverdager, helger og nasjonale helligdager. Vi vil ikke
            bli varslet for disse fluktuasjonene, så vi setter det opp til å se på ukentlige øktedata.{" "}
          </li>
          <li>
            <strong>Varselvilkårene</strong> gjelder for <strong>all trafikk</strong> og du vil bli varslet
            når <strong>økter reduseres med mer enn 35% sammenlignet med forrige uke.</strong>
          </li>
        </ul>

        <ImgScreenshot
          src="google-analytics-setup/set-up-google-analytics-alert-session-decrease.png"
          alt="sett opp Google Analytics varsler for øktereduksjon skjermbilde"
          className="article-img"
        />

        <p>
          Etterpå, trykk <strong>lagre</strong> og la oss legge til det neste varslet.
        </p>
        <p>For å sette opp et Google Analytics-varsel for økt serverresponstid,</p>
        <ul>
          <li>
            klikk på <strong>Nytt varsel</strong> og gi det et navn, som{" "}
            <em>Gj.sn. server responstid øker dag til dag</em> og
          </li>
          <li>
            velg <strong>dag</strong> som periode.
          </li>
          <li>
            For <strong>varselvilkårene</strong> gjelder det for <strong>all trafikk</strong> og du vil bli
            varslet når <strong>gj.sn. server responstid øker med mer enn 300%</strong> sammenlignet med{" "}
            <strong>forrige dag</strong>:
          </li>
        </ul>

        <ImgScreenshot
          src="google-analytics-setup/set-up-alerts-server-rsp-time-google-analytics.png"
          alt="sett opp Google Analytics varsler for server responstid skjermbilde"
          className="article-img"
        />

        <p>
          Etterpå, trykk <strong>lagre</strong>.<br />
          Nå kan du være sikker på å motta et varsel på e-post når trafikken din faller eller når serveren din har
          problemer.
        </p>
      </MainContent>
    </React.Fragment>

  </Layout>
);

export default AnalyticsSetup;
